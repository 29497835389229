import { Navigate, useRoutes,BrowserRouter,Route,Routes } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import { UseContextState } from './global/GlobalContext/GlobalContext';

import User from './pages/User';
import Login from './pages/Login';
import Products from './pages/Products';
import Orders from './pages/Orders';
import Category from './pages/Category';
import ProtectedRoute from './utils/ProtectedRoute';
import Banners from './pages/Banners';


// ----------------------------------------------------------------------

export default function Router() {
  const {authState} = UseContextState()

  console.log("AUTHSTATE",authState)
  const userState = authState.isAuthenticated
     return (
     <Routes>
     <Route element={<ProtectedRoute/>} >
     <Route exact path="/"  element={ <Navigate to='/dashboard/orders' />    }  />    
     <Route path="/dashboard/users" element={ <DashboardLayout Component={<User/>} />} />
     <Route path="/dashboard/products" element={ <DashboardLayout Component={<Products/>} />} />
     <Route path="/dashboard/orders" element={ <DashboardLayout Component={<Orders/>} />} />
     <Route path="/dashboard/categories" element={ <DashboardLayout Component={<Category/>} />} />
     <Route path="/dashboard/banners" element={ <DashboardLayout Component={<Banners/>} />} />
   
      </Route>
       <Route exact path="/login" element={<LogoOnlyLayout Component={<Login/>} />   } />
       <Route exact path="*" element={<Navigate to="/login" />   } />
       


   </Routes>

    )
}
